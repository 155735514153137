.goal-pill-completed {
  min-height: 30px;
  padding: 0px 20px;

  max-width: 400px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8em;

  border-radius: 4px;
  border: 0;

  transition: background-color 0.2s;
  background-color: var(--greenTransparent);
  color: var(--green);
}

.goal-pill-not-completed {
  min-height: 30px;
  padding: 0px 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8em;

  border-radius: 4px;
  border: 0;

  transition: background-color 0.2s;
  background-color: var(--redTransparent);
  color: var(--red);
}
