.emotion-button-container {
  height: 100px;
  width: 100px;

  border: 0;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color 0.2s;

  &:hover {
    background-color: var(--primary);
  }
}

.emotion-button-icon {
  width: 70px;
  height: 70px;
}

.emotion-button-selected {
  background-color: var(--primary);
}

.emotion-button-unselected {
  background-color: var(--primaryLight);
}
