.sidenav-container {
  color: var(--text);

  height: 100%;

  width: 50px;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
}

.sidenav-logo {
  width: 30px;
  height: 30px;

  margin-top: 10px;
}

.sidenav-section-2 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.sidenav-section-3 {
  font-size: 1em;
  color: var(--primaryLighter);
  margin-bottom: 10px;
}

.sidenav-user-icon-button {
  height: 40px;
  width: 40px;

  border: none;
  border-radius: 50%;

  background-color: transparent;

  cursor: pointer;
  color: var(--primaryLighter);
  font-size: 1em;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s ease-in-out;
}

.sidenav-user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
