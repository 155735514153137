.goals-drawer {
  position: absolute;
  height: 100%;
}

.goals-drawer-body {
  width: 400px;
  max-height: 100vh;

  overflow-y: scroll;

  background-color: var(--primary);

  height: 100%;

  @media screen and (max-width: 1070px) {
    width: 300px;
  }
}

.goals-drawer-info {
  padding: 20px;
}

.goals-drawer-close {
  background-color: rgba(255, 255, 255, 0.1);

  height: 50px;
  width: 50px;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: calc(100vw - 425px);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 2em;
  color: var(--text);

  transition: 0.2s;

  z-index: 99999;

  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
