@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Pages
@import "./pages/home.scss";
@import "./pages/profile.scss";

// Components
@import "./components/sidenav.scss";
@import "./components/homeLoggedIn.scss";
@import "./components/homeLoggedOut.scss";
@import "./components/createPostModal.scss";
@import "./components/postTile.scss";

// Sub-components
@import "./components/sub-components/navButton.scss";
@import "./components/sub-components/emotionButton.scss";
@import "./components/sub-components/singleLineInput.scss";
@import "./components/sub-components/activityPill.scss";
@import "./components/sub-components/goals.scss";
@import "./components/sub-components/notificationBar.scss";
@import "./components/sub-components/goalPill.scss";
@import "./components/sub-components/loading.scss";
@import "./components/sub-components/goalsDrawer.scss";
@import "./components/sub-components/signInButton.scss";

:root {
  --primary: #1f2537;
  --primaryLight: #2e3a53;
  --primaryLighter: #545d75;
  --text: rgba(232, 230, 227, 0.87);
  --textDark: rgb(41, 41, 41);
  --red: #d04249;
  --redTransparent: #d0424980;
  --green: #3aac9e;
  --greenTransparent: #3aac9e80;
  --greenLight: #5bcabd;
  --blue: #0d55f3;
  --blueLight: #2868f2;
  --blueLighter: rgb(94, 140, 239);
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--primary);
  overscroll-behavior-y: none;

  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}
