.modal-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal-body {
  width: 500px;
  height: 100%;
  max-height: 750px;
  overflow-y: scroll;
  padding: 0px 75px;
  background-color: var(--primaryLight);
  border-radius: 4px;
  z-index: 1;

  @media screen and (max-width: 600px) {
    padding: 0px 20px;
  }
}

.modal-menu {
  background-color: var(--primary);
}

.modal-section-1 {
  margin-top: 20px;
}

.modal-section-1-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal-section-1-header-close {
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--primaryLighter);

  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: var(--primaryLight);
  }
}

.modal-section-1-emotions {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.modal-section-1-emotions-more-button {
  height: 100px;
  width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--primaryLight);

  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: var(--primary);
  }
}

.modal-section-1-emotions-more-buttom-image {
  height: 50px;
  width: 50px;
}

.modal-section-2 {
  margin-top: 20px;
}

.modal-section-2-textarea {
  height: 100px;
  margin-top: 10px;

  background-color: var(--primary);
}

.modal-section-3 {
  margin-top: 20px;
  width: 100%;
}

.modal-section-3-activities {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  margin-top: 10px;
}

.modal-section-4 {
  margin-top: 20px;
}

.modal-section-5 {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.modal-submit-button {
  width: 100px;
  height: 30px;
  border-radius: 4px;
  border: 0;
  background-color: var(--primaryLighter);
  color: var(--text);
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: var(--primary);
  }
}

.modal-section-6 {
  margin: 20px 0;
}
