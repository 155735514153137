.sign-in-button-container {
  height: 40px;
}

.signin-button-body {
  height: 100%;
  width: 100%;

  color: var(--text);

  border-radius: 20px;
  border: 0 solid transparent;

  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.25px;
  cursor: pointer;
  padding: 2.5px;

  display: flex;
  align-items: center;

  padding-right: 15px;

  transition: 0.3s;
  &:hover {
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }
}

.sign-in-button-image {
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;

  background-color: white;
  padding: 5px;

  border-radius: 20px;
  border: 0;
  border-color: transparent;
  border-style: solid;
}
