.home-wrapper {
  width: 100%;
  height: 100%;

  overflow-y: hidden;

  display: flex;
  justify-content: center;
}

.home-body {
  // width: 100%;
  // height: 100%;

  display: flex;
  flex-direction: column;
}
