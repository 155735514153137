.home-logged-out-container {
  width: 100vw;
  height: 100%;
}

.home-logged-out-body {
  display: flex;
  height: 100%;
}

.home-logged-out-signin {
  margin-top: 40px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 40px;
}

.home-logged-out-left {
  width: 50%;
  height: 100%;
  max-height: 100vh;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1070px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.home-logged-out-title {
  max-width: 400px;
  text-align: center;

  @media screen and (max-width: 1070px) {
    font-size: 1.25em;
  }
}

.home-logged-out-signing-up-title {
  max-width: 400px;
  text-align: center;

  font-size: 1.2em;

  @media screen and (max-width: 1070px) {
    font-size: 1.25em;
  }
}

.home-logged-out-sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin: 20px 0 20px 0;
}

.home-logged-out-sign-up-button {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border: 0;
  border-radius: 4px;

  cursor: pointer;

  background-color: var(--primaryLight);

  font-size: 1em;
  font-weight: 700;
  color: var(--text);

  padding: 0 30px;
  transition: background-color 0.2s;

  margin-top: 10px;

  &:hover {
    background-color: var(--primaryLighter);
  }
}

.home-logged-out-right {
  height: 100vh;
  overflow-y: hidden;
  width: 50%;
  object-fit: cover;
}
