.post-tile-container {
  width: 100%;
}

.post-tile-body {
  background-color: var(--primaryLight);
  border-radius: 10px;

  display: flex;
  padding: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.post-tile-emotion-image-container {
  @media screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-bottom: 20px;
  }
}

.post-tile-emotion-image {
  width: 100px;
  height: 100px;
}

.post-tile-content {
  width: 100%;
  margin-left: 40px;

  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
}

.post-tile-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.post-tile-content-header {
}

.post-tile-activities {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.post-tile-goals {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
}
