.App {
  background-color: var(--primary);

  height: 100vh;
  width: 100vw;

  display: flex;
}

h1 {
  color: var(--text);
  margin-block-start: 0;
  margin-block-end: 0;

  @media screen and (max-width: 600px) {
    font-size: 1.5em;
  }
}

h2 {
  color: var(--text);
  font-weight: 500;
  margin-block-start: 0;
  margin-block-end: 0;
}

h3 {
  font-size: 1em;
  color: var(--text);
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  color: var(--primaryLighter);
  margin-block-start: 0;
  margin-block-end: 0;

  margin: 10px 0;
}

textarea {
  height: 100%;
  width: 100%;

  border: none;
  border-radius: 4px;

  resize: none;

  // background-color: var(--primary);
  font-size: 1.25em;
  color: var(--text);

  transition: background-color 0.2s;
  &:focus-visible {
    outline: var(--primaryLighter) solid 1px;
  }
}

.MuiMenu-paper {
  background-color: green;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryLighter);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryLight);
}

// button {
//   background-color: var(--primaryLighter);
//   border: none;
//   border-radius: 5px;
//   color: var(--text);
//   cursor: pointer;
//   font-size: 1rem;
//   font-weight: 600;
//   padding: 0.5rem 1rem;
//   transition: background-color 0.2s ease-in-out;

//   &:hover {
//     background-color: var(--primaryLight);
//   }
// }
