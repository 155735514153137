.profile-container {
  width: 100%;
  max-height: 100vh;

  overflow-y: scroll;

  display: flex;
  justify-content: center;
}

.profile-body {
  width: 100%;
  max-width: 1000px;
}

.profile-header {
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-header-logout-button {
  width: 100px;
  height: 40px;

  border-radius: 4px;
  border: 0;

  cursor: pointer;

  background-color: var(--primaryLighter);
  color: var(--text);
  font-size: 1em;
  font-weight: 700;

  transition: background-color 0.2s;

  &:hover {
    background-color: var(--primaryLight);
  }
}

.profile-content {
  width: 100%;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
}

.profile-content-inputs {
  width: 60%;

  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}

.profile-input-with-header {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.profile-switch-with-header {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.profile-input {
  width: 200px;
  height: 40px;

  border-radius: 4px;
  border: 0;
  padding: 0 10px;

  background-color: var(--primaryLighter);
  color: var(--text);
  font-size: 1.2em;
  font-weight: 700;

  &:focus-visible {
    outline: none;
  }
}

.profile-switch {
  width: 220px;
}

.profile-picture {
  width: 200px;
  height: 200px;

  border-radius: 50%;
  border: 0;

  background-color: var(--primaryLighter);
  color: var(--text);
  font-size: 1.2em;
  font-weight: 700;
}

.profile-goals-section {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  align-items: center;

  margin: 40px 0 40px 0;
}

.profile-goals {
  width: 100%;
  max-width: 500px;
}

.profile-goals-container {
  width: 100%;
}

.profile-submit-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.profile-submit-section-button {
  width: 100px;
  height: 40px;

  border-radius: 4px;
  border: 0;

  cursor: pointer;

  background-color: var(--blue);
  color: var(--text);
  font-size: 1em;
  font-weight: 700;

  transition: background-color 0.2s;

  &:hover {
    background-color: var(--blueLight);
  }
}
