.loading-container {
  width: 100%;
  height: 45px;
  background-color: var(--blueLighter);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 20px;
}

.rotate-img {
  animation: rotation 2s infinite linear;

  width: 50px;
  height: 50px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
