.notification-bar-container {
  width: 100%;
  height: 40px;
  color: var(--text);
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
}

.notification-bar-failure {
  background-color: var(--red);
}

.notification-bar-loading {
  background-color: var(--blue);
}

.notification-bar-success {
  background-color: var(--greenLight);
}
